<script>
import def from "@/components/business/time/eventsList";
export default {
  extends: def,
  components: {
    eventsCheck() {
      return import("./eventsCheck");
    }
  },
  methods: {
    getEvents() {
      return this.$store.state.privateEvent.unsaved.events;
    },
    setSave() {
      var data = JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      );
      data.events = this.events;
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
};
</script>
