<template>
  <ol v-if="getEvents().length" :key="updateKey">
    <li
      style="padding: 20px"
      v-for="(event, index) in getEvents()"
      :key="index"
    >
      <h4>{{ $t("settings.event.title", { count: index + 1 }) }}</h4>
      <div class="flix-btn-remove">
        <a href="#" @click.prevent="doublicateEvent(index)" class="flix-html-a"
          ><icon id="copy-duplicate"
        /></a>
        <a
          href="#"
          class="flix-html-a"
          v-if="getEvents().length > 1"
          @click.prevent="deleteEvent(index)"
          ><icon id="menu-close"
        /></a>
      </div>
      <inputText
        style="width: 100%; box-sizing: border-box"
        :data="event.value.name"
        :callback="function (value, all) { setTitle(all, index) }"
        :help="$t('settings.event.appointment.info')"
        :error="false"
        :styling="{
          input:
            'width: 100%; max-width: 300px; display: block; box-sizing: border-box'
        }"
        :placeholder="$t('settings.event.appointment.placeholder')"
        :label="$t('settings.event.appointment.title')"
      />
      <div class="flix-flex flix-flex-list flix-gap-5 flix-form-group">
        <label class="flix-label"
          >{{ $t("settings.event.time.title") }}
          <help>{{ $t("settings.event.time.info") }}</help></label
        >
        <div
          class="flix-flex flix-flex-center flix-form-group"
          style="justify-content: flex-start; gap: 20px"
        >
          <datePicker
            :range="5"
            :time="event.begin"
            :callback="function (d) { setDate(index, 'begin', d); }"
          />
          <div v-if="event.end != event.begin">
            {{ $t("time.period.description").split("{date}")[1] }}
          </div>
          <div v-if="event.end != event.begin">
            <datePicker
              :range="5"
              :time="event.end"
              :callback="function (d) { setDate(index, 'end', d); }"
            />
          </div>
          <eventsCheck :index="index" />
        </div>
        <div>
          <a
            href="#"
            class="flix-btn-end flix-flex flix-flex-center flix-flex-inline flix-flex-center"
            v-if="event.end != event.begin"
            @click.prevent="setEnd(index, false)"
          >
            <icon class="icon active" id="square-check" />
            {{ $t("settings.event.addEnd") }}
          </a>
          <a
            href="#"
            class="flix-btn-end flix-flex flix-flex-center flix-flex-inline flix-flex-center"
            v-else
            @click.prevent="setEnd(index, true)"
          >
            <icon class="icon" id="square-empty" />
            {{ $t("settings.event.addEnd") }}
          </a>
        </div>
        <div v-if="$store.state.type === 'business'">
          <maxApplicants :event="event" :index="index" />
        </div>
      </div>
    </li>
  </ol>
</template>
<script>
export default {
  components: {
    maxApplicants() {
      return import("@/components/business/applicants/maxEventApplicants.vue");
    },
    datePicker() {
      return import("@/components/default/form/datePicker");
    },
    eventsCheck() {
      return import("./eventsCheck");
    }
  },
  props: {},
  data() {
    return {
      events: this.getEvents(),
      updateKey: 0
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getData(index) {
      if (typeof this.events[index].value === "undefined") {
        return false;
      }
      if (typeof this.events[index].value.name === "undefined") {
        return false;
      }
      return this.events[index].value.name;
    },
    setTitle(value, index) {
      this.events = this.getEvents();
      this.events[index].value.name = value;
      this.events[index].value.label = value;
      this.setSave();
    },
    getEvents() {
      return this.$store.state.business.unsaved.events;
    },
    doublicateEvent(index) {
      var dublicate = JSON.parse(JSON.stringify(this.events))[index];

      this.events.splice(index + 1, 0, dublicate);

      this.setSave();
      this.setMaxApplicants(index);
      this.updateKey++;
    },
    setMaxApplicants(index) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      var currMaxApplicants = data.max_applicants[index];
      data.max_applicants.splice(index + 1, 0, currMaxApplicants);
      this.$store.commit("business/prefetch", data);
    },
    deleteEvent(index) {
      var events = JSON.parse(JSON.stringify(this.getEvents()));
      events.splice(index, 1);
      this.events = events;
      this.setSave();
      this.updateKey++;
    },
    setDate(index, a, d) {
      var check = false;
      var events = JSON.parse(JSON.stringify(this.getEvents()));

      if (events[index].begin === events[index].end) {
        check = true;
      }

      if (check === true) {
        events[index].begin = d;
        events[index].end = d;
      } else {
        events[index][a] = d;
      }

      this.events = events;
      this.setSave();
    },
    setEnd(index, add) {
      var events = JSON.parse(JSON.stringify(this.getEvents()));

      var d1 = new Date();
      d1.setYear(events[index].begin.split(" ")[0].split("-")[0]);
      d1.setMonth(events[index].begin.split(" ")[0].split("-")[1] * 1 - 1);
      d1.setDate(events[index].begin.split(" ")[0].split("-")[2]);
      d1.setHours(events[index].begin.split(" ")[1].split(":")[0] * 1 + 1);
      d1.setMinutes(events[index].begin.split(" ")[1].split(":")[1]);
      d1.setSeconds(0);
      d1.setMilliseconds(0);

      if (add) {
        events[index].end =
          d1.getFullYear() +
          "-" +
          this.getNullsBefore(d1.getMonth() + 1) +
          "-" +
          this.getNullsBefore(d1.getDate()) +
          " " +
          this.getNullsBefore(d1.getHours()) +
          ":" +
          this.getNullsBefore(d1.getMinutes());
      } else {
        events[index].end = events[index].begin;
      }
      this.events = events;
      this.setSave();
    },
    getNullsBefore(index) {
      if (index * 1 < 10) {
        return "0" + index;
      } else {
        return index + "";
      }
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.events = this.events;
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
ol
  margin: 0
  padding: 0
  display: flex
  align-items: center
  justify-content: flex-start
  flex-wrap: wrap
  margin-bottom: 20px
  margin-left: -10px
  margin-right: -10px
  margin-top: -10px
  li
    list-style: none
    padding: 10px
    border: 1px solid $grey
    width: 100%
    margin: 10px
    border-radius: 5px
    position: relative
    a.flix-btn-end
      text-decoration: none
      margin-left: 5px
      justify-content: flex-start
      gap: 5px
      color: $dark-grey
      .icon
        color: $middle-grey
        font-size: 20pt
        &.active
          color: $success
    .flix-btn-remove
      margin-right: 10px
      position: absolute
      right: 0
      top: 20px
      font-size: 12pt
      display: flex
      align-items: center
      justify-content: center
      gap: 10px
      a
        color: $dark-grey
    .down
      display: none
    .right
      display: flex


@media(max-width: 500px)
  ol
    justify-content: center
    li
      flex-direction: column
      a.flix-btn-end
        margin: 10px 0 0 0
      a.flix-btn-remove
        margin: 0 0 10px 0
        text-align: right
        display: flex

      .down
        display: flex
      .right
        display: none
</style>
